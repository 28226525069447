import React from 'react';
import tw, { css, styled } from 'twin.macro';
import { graphql, useStaticQuery } from 'gatsby';
import { ReactSVG } from 'react-svg';
import { Inner, Section, SectionTitle } from 'components/index';
import { BubblesDecoration } from 'components/Decorations';
import { useFilterContext } from 'contexts/filter';

const numberStyles = tw`
  text-4xl sm:text-6xl
`;

const iconStyles = css`
  svg {
    max-width: 250px;
    max-height: 185px;

    @media only screen and (max-width: 1024px) {
      max-width: 141px;
      max-height: 105px;
    }
  }
`;

const bubblesStyles = css`
  ${tw`absolute`}
  left: 36%;
  top: -150px;
  @media only screen and (max-width: 1024px) {
    left: -15px;
    top: -175px;
    transform: rotate(-65deg);
  }
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const Facts = styled.ul`
  ${tw`grid grid-cols-2 md:grid-cols-4 items-center`}
`;
const Fact = styled.li`
  height: 190px;
  ${tw`mr-16 sm:(w-1/4 h-full)`}
`;

const DenmarkInNumbers = ({ heading, bodyText, facts, noJobs, ...rest }) => {
  const { jobsCount, roleFilters, selectedCountry } = useFilterContext();
  const {
    allDatoCmsLocationCountry: { edges },
  } = useStaticQuery(expatsQuery);
  const allCountries = edges.map(c => c.node);

  const expatsInCountry =
    allCountries.find(c => c.country === selectedCountry)?.numberOfExpatsLivingInDk ||
    allCountries.find(c => c.isoStandardName === selectedCountry)?.numberOfExpatsLivingInDk;

  return (
    <Section tw="sm:mt-10" {...rest}>
      <Inner tw="relative">
        <BubblesDecoration css={bubblesStyles} />
        {heading && <SectionTitle>{heading}</SectionTitle>}
        {bodyText && <p tw="lg:w-1/2 mt-2 mb-10 sm:mb-4">{bodyText}</p>}
        <Facts>
          {facts?.map(({ label, number, icon }, i) => {
            if (noJobs && i === 3) return null;
            let num;
            if (i === 2) num = expatsInCountry || '215,000';
            else if (i === 3) num = jobsCount;
            else num = number;
            if (!num) return null;
            return (
              <Fact key={number}>
                <div tw="relative">
                  <h1 tw="towards absolute bottom-0" css={numberStyles}>
                    {num}
                  </h1>
                  <div css={iconStyles}>
                    <ReactSVG src={icon.url} />
                  </div>
                </div>
                <span tw="inline-block mt-4 font-semibold text-xxs w-44 h-10 sm:text-sm">
                  {i === 3 && roleFilters.length === 0
                    ? 'Jobs on thehub.io'
                    : i === 2 && expatsInCountry
                    ? `Expats working & living in Denmark from ${selectedCountry}`
                    : label}
                </span>
              </Fact>
            );
          })}
        </Facts>
      </Inner>
    </Section>
  );
};

export default DenmarkInNumbers;

const expatsQuery = graphql`
  {
    allDatoCmsLocationCountry {
      edges {
        node {
          country
          numberOfExpatsLivingInDk
          isoStandardName
        }
      }
    }
  }
`;
